<template>
    <div>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card :header="$t('graduate_info')" class="soft mb-5">
                    <b-row>
                        <b-col cols="12" lg="3">
                            <div class="label-as-input-div">
                                <label>{{ $t('faculty') }}</label>
                                <div>{{ graduateData.faculty_code }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="3">
                            <div class="label-as-input-div">
                                <label>{{ $t('national_number') }}</label>
                                <div>{{ graduateData.national_id }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="3">
                            <div class="label-as-input-div">
                                <label>{{ $t('graduate_semester') }}</label>
                                <div>{{ graduateData.semester }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="3">
                            <div class="label-as-input-div">
                                <label>{{ $t('birthdate') }}</label>
                                <div>{{ graduateData.birthdate }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="3">
                            <div class="label-as-input-div">
                                <label>{{ $t('department') }}</label>
                                <div>{{ graduateData.department_code }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="3">
                            <div class="label-as-input-div">
                                <label>{{ $t('name') }}</label>
                                <div>{{ graduateData.name }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="3">
                            <div class="label-as-input-div">
                                <label>{{ $t('graduate_period') }}</label>
                                <div>{{ graduateData.period }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="3">
                            <div class="label-as-input-div">
                                <label>{{ $t('last_updated') }}</label>
                                <div>{{ graduateData.last_updated }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="3">
                            <div class="label-as-input-div">
                                <label>{{ $t('program') }}</label>
                                <div>{{ graduateData.program_code }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="3">
                            <div class="label-as-input-div">
                                <label>{{ $t('surname') }}</label>
                                <div>{{ graduateData.surname }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="3">
                            <div class="label-as-input-div">
                                <label>{{ $t('graduation_date') }}</label>
                                <div>{{ graduateData.graduation_date }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="3">
                            <div class="label-as-input-div">
                                <label>{{ $t('last_update_datetime') }}</label>
                                <div>{{ graduateData.last_update_date }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="12">
                            <b-button variant="primary" @click="$emit('updateClick',['graduate_info'])">{{ $t('edit') }}</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Template

// Components

//Pages

// Services

// Others

export default {
    props: {
        graduateData: {
            type: Object,
            default: {}
        },
    },

    components: {
    },

    data() {
        return {

        }
    },
    created() {

    },
    methods: {

    }
}
</script>

