<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="6">
                    <ValidationProvider name="meeting_note" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('meeting_note')">
                            <b-form-textarea v-model="formData.meeting_note"
                                             :class="errors[0] ? 'is-invalid':''"
                                             rows="6"
                            />
                            <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="6">
                    <ValidationProvider name="meeting_status" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('meeting_status')">
                            <multi-selectbox
                                :multiple="false"
                                :validate-error="errors[0]"
                                :options="meetingStatusOptions"
                                v-model="formData.meeting_status" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" lg="12" class="d-flex justify-content-end">
                    <b-button variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
// Template

// Components

//Pages

// Services

// Others
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    props: {
        graduateData: {
            type: Object,
            default: {}
        },
    },

    components: {
        ValidationObserver,
        ValidationProvider,
    },

    data() {
        return {
            formData: {},
            meetingStatusOptions: [
                {
                    text: 'BARC',
                    value: 'BARC'
                },
                {
                    text: 'Beklemede',
                    value: 'Beklemede'
                },
                {
                    text: 'Bilgilendirme istedi',
                    value: 'Bilgilendirme istedi'
                },
                {
                    text: 'İletişim İzni Yok',
                    value: 'İletişim İzni Yok'
                },
                {
                    text: 'İlgilenmiyor',
                    value: 'İlgilenmiyor'
                },
                {
                    text: 'İptal',
                    value: 'İptal'
                },
                {
                    text: 'Kullanılmayan Numara',
                    value: 'Kullanılmayan Numara'
                },
                {
                    text: 'Olumlu',
                    value: 'Olumlu'
                }
            ]
        }
    },
    created() {

    },
    methods: {
        async saveForm(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                this.$toast.success(this.$t('successfully_saved'))
                this.$emit('updateSuccess')
            }
        },
    }
}
</script>

