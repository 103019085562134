import i18n from '@/plugins/i18n';

export default function (role = 'user',studentProgram={}) {
    let menus = [
        {
            title: i18n.t('graduate_info'),
            title_short: i18n.t('graduate_info'),
            slug: 'graduate_info',
            permission: 'studentprogram_show',
            show: true,
            showStudent: false
        },
        {
            title: i18n.t('personal_info'),
            title_short: i18n.t('personal_info'),
            slug: 'personal_info',
            permission: 'studentprogram_show',
            show: true,
            showStudent: false
        },
        {
            title: i18n.t('job_information'),
            title_short: i18n.t('job_information'),
            slug: 'job_information',
            permission: 'studentprogram_show',
            show: true,
            showStudent: false
        },
        {
            title: i18n.t('education_info'),
            title_short: i18n.t('education_info'),
            slug: 'education_info',
            permission: 'studentprogram_show',
            show: true,
            showStudent: false
        },
        {
            title: i18n.t('contact_confirmations'),
            title_short: i18n.t('contact_confirmations'),
            slug: 'contact_confirmations',
            permission: 'studentprogram_show',
            show: true,
            showStudent: false
        },
        {
            title: i18n.t('other'),
            title_short: i18n.t('other'),
            slug: 'other',
            permission: 'studentprogram_show',
            show: true,
            showStudent: false
        },
        {
            title: i18n.t('work_history'),
            title_short: i18n.t('work_history'),
            slug: 'work_history',
            permission: 'studentprogram_show',
            show: true,
            showStudent: false
        },
        {
            title: i18n.t('meetings'),
            title_short: i18n.t('meetings'),
            slug: 'meetings',
            permission: 'studentprogram_show',
            show: true,
            showStudent: false
        }
    ];

    return menus
}


