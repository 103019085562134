<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('graduate')"
                        :isFilter="false"
                        :is-back-button="true"
                        :back-button="backButton"
                        :selected-menu="selectedMenu" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('graduate')"
                              :isFilter="false"
                              :is-back-button="true"
                              :back-button="backButton"
                              :selected-menu="selectedMenu" />
            </template>
            <div class="d-flex flex-column flex-lg-row">
                <div class="mr-lg-5">
                    <info-page :studentProgram="responseData" :editMode="true" />
                </div>
                <div class="mr-lg-5">
                    <menu-page v-model="selectedMenu" :studentProgram="responseData" :menu-data="menuData" />
                    <b-dropdown :text="computeDropdownMenuName" block split split-variant="outline-primary"
                                variant="primary" class="d-block d-lg-none secondary-border-lighter btn-outline-secondary mb-3">
                        <template v-for="menu in menuData">
                            <b-dropdown-item :key="'dropdownMenu' + menu.slug" @click="selectedMenu = menu.slug"
                                             v-if="(menu.permission == '' || checkPermission(menu.permission)) && menu.show">
                                {{ menu.title.toUpper() }}
                            </b-dropdown-item>
                        </template>
                    </b-dropdown>
                </div>
                <div class="flex-grow-1 w-100 w-lg-0" v-if="responseData">
                    <component :is="selectedComponent" :graduateData="responseData" @updateClick="callUpdateModal"></component>
                </div>
            </div>
            <CommonModal ref="updateModal" size="xl">
                <template v-slot:CommonModalTitle>
                    {{ updateModalTitle.toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <component :is="updateNavi" v-if="updateNavi" :graduateData="responseData" :updateData="updateData" @updateSuccess="closeModal" />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

// Components
import CommonModal from "@/components/elements/CommonModal";

//Pages
import GraduateInfo from "./actionTabs/GraduateInfo"
import ContactConfirmations from "./actionTabs/ContactConfirmations"
import EducationInfo from "./actionTabs/EducationInfo"
import JobInformation from "./actionTabs/JobInformation"
import Other from "./actionTabs/Other"
import PersonalInfo from "./actionTabs/PersonalInfo"
import WorkHistoryAdd from "./actionTabs/WorkHistoryAdd"
import WorkHistoryEdit from "./actionTabs/WorkHistoryEdit"
import MeetingsAdd from "./actionTabs/MeetingsAdd"
import MeetingsEdit from "./actionTabs/MeetingsEdit"
import EducationInfoAdd from "./actionTabs/EducationInfoAdd"
import EducationInfoEdit from "./actionTabs/EducationInfoEdit"

import GraduateInfoTab from "./tabs/GraduateInfo"
import PersonalInfoTab from "./tabs/PersonalInfo"
import JobInformationTab from "./tabs/JobInformation"
import EducationInfoTab from "./tabs/EducationInfo"
import ContactConfirmationsTab from "./tabs/ContactConfirmations"
import OtherTab from "./tabs/Other"
import WorkHistoryTab from "./tabs/WorkHistory"
import MeetingsTab from "./tabs/Meetings"

import InfoPage from "./InfoPage"
import MenuPage from "./MenuPage"
import menuData from "@/modules/graduates/data/Menus"

// Services

// Others

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        CommonModal,

        GraduateInfoTab,
        PersonalInfoTab,
        JobInformationTab,
        EducationInfoTab,
        ContactConfirmationsTab,
        OtherTab,
        WorkHistoryTab,
        MeetingsTab,

        GraduateInfo,
        ContactConfirmations,
        EducationInfo,
        JobInformation,
        Other,
        PersonalInfo,
        WorkHistoryAdd,
        WorkHistoryEdit,
        MeetingsAdd,
        MeetingsEdit,
        EducationInfoAdd,
        EducationInfoEdit,

        InfoPage,
        MenuPage,
    },

    metaInfo() {
        return {
            title: this.$t('graduate')
        }
    },

    data() {
        return {
            responseData: null,
            pageTitle: this.$t('graduate_info'),
            componentNavi: 'GraduateInfoTab',
            microTabs: [
                {
                    name: this.$t('graduate_info'),
                    component: 'GraduateInfoTab'
                },
                {
                    name: this.$t('work_history'),
                    component: 'WorkingHistoryTab'
                },
                {
                    name: this.$t('meetings'),
                    component: 'MeetingsTab'
                }
            ],
            updateModalTitle: '',
            updateNavi: null,
            updateData: null,

            selectedMenu: 'graduate_info',
            selectedComponent: 'GraduateInfoTab',
            menuData: null,
        }
    },
    created() {
        this.getData()
        this.menuData = menuData(null, this.responseData)
    },
    methods: {
        tabNavi(elem){
            this.pageTitle = elem.name
            this.componentNavi = elem.component
        },

        callUpdateModal(data,updateData){
            this.updateModalTitle = this.$t(data[data.length - 1])
            this.updateNavi = this.createTabName(data)
            this.updateData = updateData

            this.$refs.updateModal.$refs.commonModal.show()
        },

        closeModal(){
            this.$refs.updateModal.$refs.commonModal.hide()
            this.getData()
        },

        createTabName(str){
            let response = ''
            let dataArr = str[0].split('_')

            dataArr.forEach(itm => {
                response += itm.charAt(0).toUpperCase() + itm.slice(1)
            })

            if(str.length > 1) {
                response += str[1].charAt(0).toUpperCase() + str[1].slice(1)
            }

            return response
        },

        createComponentTabName(str){
            return this.createTabName([str])+'Tab'
        },

        getData(){
            let getServiceData = {
                student_number: 2254044,
                name: "Feridun",
                surname: "Hatipoğlu",
                faculty_code: "Fen-Edebiyat Fakültesi",
                program_code: "Sosyoloji",
                national_id: 11111111111,
                card_status: "Kart Oluşturuldu",
                issue_date: "21.11.2023",
                email: "abc@xyz.com",
                mobile_number: "0537 777 77 77",
                city: "İzmir",
                district: "Pınarbaşı",
                semester: "2017-2018",
                period: "Bahar",
                department_code: "Sosyoloji",
                working_status: "Çalışıyor",
                not_working_reason: "Askerde",
                death_status: "Sağ",
                marriage_status: "Evli",
                id: 1,
                birthdate: "20.11.1992",
                last_updated: null,
                graduation_date: "21.11.2023",
                last_update_date: null,
                personal_email: "fero@xyz.com",
                country: "Türkiye",
                address: "Çayırbaşı Mah. No.27",
                children_available: "Yok",
                working_corporation: "Şahinler Holding",
                positional_status: "Yazılım Uzmanı",
                sector: "Yazılım",
                position_detail: null,
                do_you_think_to_do_master_in_corp: "Evet",
                educations: [
                    {
                        education_status: "Lisans",
                        university: "Technical University Varna",
                        graduate_date: "19.06.2009",
                        diploma_number: "7777777",
                        diploma_point: null
                    }
                ],
                approve_sms: "Evet",
                approve_email: "Hayır",
                approve_call: "Hayır",
                graduate_email_status: null,
                kvkk_code: null,
                workingHistories: [
                    {
                        work_start_date: "11.11.2011",
                        work_leave_date: null,
                        corporation: "Şeker GYO",
                        corp_department: "Yazılım",
                        working_type: "Tam Zamanlı",
                        working_tract: "Alan Dışı",
                        id: 1
                    }
                ],
                meetings: [
                    {
                        register_date: "11.09.2023",
                        meeting_note: "Test, deneme, lorem ipsium.",
                        meeting_status: "Beklemede",
                        id: 1
                    }
                ]
            }

            this.responseData = getServiceData
        },

        backButton() {
            this.$router.push('/graduates')
        },
    },

    computed: {
        computeDropdownMenuName: {
            get() {
                let selected = (this.menuData || []).find(item => item.slug == this.selectedMenu)
                return selected ? this.toUpperCase(selected.title) : '';
            },
            set() {

            }
        },
    },

    watch: {
        selectedMenu: {
            handler(newValue){
                this.selectedComponent = this.createComponentTabName(newValue)
            }
        }
    }
}
</script>

